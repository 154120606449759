var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.ApplicationClientUpdate.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, validated, passes, validate }){return [_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-subheader',{staticClass:"headline"},[_vm._v(_vm._s(_vm.heading))])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{ref:"client",attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-autocomplete',{attrs:{"items":_vm.clientItems,"error":!valid,"error-messages":errors,"search-input":_vm.clientSearching,"item-text":"name","item-value":"id","prepend-icon":"mdi-account-supervisor","loading":_vm.loadingClients,"disabled":_vm.loading,"no-data-text":_vm.clientSearching
                                            ? _vm.$t('nothing_found_by', {
                                                  search: _vm.clientSearching,
                                              })
                                            : _vm.$t(
                                                  'nothing_found_client_name'
                                              ),"label":_vm.$t('client'),"color":"primary","return-object":"","clearable":"","autocomplete":"off"},on:{"update:searchInput":function($event){_vm.clientSearching=$event},"update:search-input":function($event){_vm.clientSearching=$event},"click":_vm.clearClients,"click:clear":function($event){_vm.trackingItems = []}},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-6"},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-progress-linear',{staticClass:"mx-2",attrs:{"active":_vm.loading},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-spacer'):_vm._e(),_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading,"loading":_vm.loading,"block":_vm.$vuetify.breakpoint.xsOnly,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("update"))+" ")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }