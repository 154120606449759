<template>
    <v-container fluid>
        <v-form @submit.prevent="ApplicationClientUpdate">
            <ValidationObserver
                ref="observer"
                v-slot="{ invalid, validated, passes, validate }"
            >
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-subheader class="headline">{{
                                    heading
                                }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider
                                    ref="client"
                                    rules=""
                                    v-slot="{ errors, valid }"
                                >
                                    <v-autocomplete
                                        v-model="client"
                                        :items="clientItems"
                                        :error="!valid"
                                        :error-messages="errors"
                                        :search-input.sync="clientSearching"
                                        item-text="name"
                                        item-value="id"
                                        prepend-icon="mdi-account-supervisor"
                                        :loading="loadingClients"
                                        :disabled="loading"
                                        @click="clearClients"
                                        :no-data-text="
                                            clientSearching
                                                ? $t('nothing_found_by', {
                                                      search: clientSearching,
                                                  })
                                                : $t(
                                                      'nothing_found_client_name'
                                                  )
                                        "
                                        :label="$t('client')"
                                        @click:clear="trackingItems = []"
                                        color="primary"
                                        return-object
                                        clearable
                                        autocomplete="off"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-6">
                        <v-progress-linear
                            v-if="!$vuetify.breakpoint.xsOnly"
                            v-model="progress"
                            :active="loading"
                            class="mx-2"
                        ></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn
                            type="submit"
                            :disabled="invalid || loading"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            color="primary"
                        >
                            {{ $t("update") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
    name: "ApplicationClientUpdateForm",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    inject: ["forceRerender"],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            client: null,
            clientItems: [],
            loadingClients: false,
            clientSearching: null,
            exist_translations: {},
            all_translations: true,
        };
    },
    computed: {
        ...mapGetters(["listLanguages", "lang"]),
        languages() {
            return this.listLanguages;
        },
    },
    mounted() {
        this.language = this.languages[this.tab];
        this.checkCreate();
    },
    watch: {
        clientSearching: debounce(function (val) {
            // if (val && !this.client) {
            this.getClients(val);
            // }
        }, 500),
    },
    methods: {
        checkCreate() {
            this.heading = this.$t("menu_update_application_client");
        },
        setLanguage(val) {
            this.language = this.languages[val];
            this.checkCreate();
        },
        clearClients() {
            if (!this.client) {
                this.clientItems = [];
            }
            this.tracking = null;
            this.trackingItems = [];
        },
        async getClients(str) {
            if (str) {
                this.loadingClients = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.client = str;
                }
                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then((res) => {
                        this.clientItems = res.body.data;
                    })
                    .catch((err) => {
                        this.clientItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_clients")
                        );
                    })
                    .finally((end) => {
                        this.loadingClients = false;
                    });
            }
        },

        async ApplicationClientUpdate() {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            var formData = new FormData();

            if (
                this.client !== undefined &&
                this.client != null &&
                this.client !== ""
            ) {
                formData.append("client", this.client.id);
            }

            await this.$http
                .put(`integration/update/application/client`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    this.$toastr.success(
                        this.$t("application_update_has_been_updated")
                    );
                    if (
                        res &&
                        res.body &&
                        res.body.data &&
                        res.body.data.exist_translations
                    ) {
                        this.exist_translations =
                            res.body.data.exist_translations;
                        this.all_translations = res.body.data.all_translations;
                    }
                    //this.forceRerender()
                })
                .catch((err) => {
                    this.$toastr.error(
                        this.$t("application_update_has_not_been_updated")
                    );
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0],
                                    ]);
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message);
                        }
                    }
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
    },
};
</script>
